$corS00:#FFF689;
$corS01:#1CADE2;
$corS02:#0E8FC0;
$corS03:#FF4000;
$corFF: #FFF;
$corF8: #F8F8F8;
$corF4: #f4f6f9;
$corF3: #f3f5f8;
$corED: #EDEDED;
$corEC: #ecedf2;
$corDD: #DDD;
$corCC: #CCC;
$corBB: #BBB;
$corAA: #AAA;
$cor77: #777;
$cor00: #000;
$corFF00: rgba(255,255,255,0);
$corFF10: rgba(255,255,255,0.1);
$corFF50: rgba(255,255,255,0.5);
$corFF80: rgba(255,255,255,0.8);
$cor0010: rgba(0,0,0,0.1);
$cor0020: rgba(0,0,0,0.2);
$cor0050: rgba(0,0,0,0.5);
$gray10: rgba(236,237,242,0.1);
$gray50: rgba(236,237,242,0.5);

$goldYellow: #FAFAD2;

$corGradMid: $corFF;
$corGradEnd: $corFF10;
$corGradStart: $corFF10; 

$bgBody         : $corEC;

$bgNav          : $corFF;
$txtCorNav      : $cor00;
$txtCorBody     : $cor00;
$txtCorNavOver  : $cor77;
$bgNetIcon      : $corS01;//$corCC;
$txtCorZap      : $corFF;
$corFooter      : $corFF;
$bgFooter       : $corS01; //#232323;
$bgCorZap       : #28a745;
$borderCorZap   : #1e7e34;
$bgCorTel       : #17a2b8;
$borderCorTel   : #138496;
$corPrice       : $corS01;
$corFixoOver    : $corS01;
$boxBorderCor   : $cor00;
$bgOverChip     : $corS01;
$bgOverAvatar   : $corFF;
$corOverAvatar  : $corS01;
$bgBtnsGallery  : $corFF50;
$bgCorSuccess   : #28a745;
$overCorSuccess : #1e7e34;
$bgCorDanger    : #dc3545;
$overCorDanger  : #c82333;
$bgModalHeader  : $corEC;
//$bgPriceGallery  : $corFF40;

#js-app{
    #js-app-cart-badge span{
        color: $corFF;
    }
    .stock-card-dados.in-cart::before{
        color: $corS03;
        background-color: $corS00;
    }
    .navbar{
        background-color: $corS03;
        &-nav .nav-link{
            color: $corFF !important;
            &:hover{
                color: $cor00!important;
            }
        }
        .tel-fixo{
            span:hover{
                color: $cor00!important;
            }
        }
    }
    .search-bar-fixed .search{
        border-color: $corS03;
    }
    .search-bordered{
        border-color: $corFF;
    }
    .btn-dark{
        border-color: $corF8;
        background-color: $corS03;
        &:hover{
            background-color: $corS03!important;
        }
        &.bgBlack{
            background-color: $corFF;
            &:hover{
                background-color: $corFF!important;
            }
        }
    }
    .bt-back{
        color: $corFF;
        background-color: $corS03;
        &:hover{
            color: $cor00;
        }
    }

    .MuiChip-root {
        border: 2px solid $corFF50;
        .MuiChip-avatar{
            color: $corS03;
        }
        &.btn-start{
            width: 150px;
        }
    }
}


